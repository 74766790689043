/***********************************/
 
/* FOOTER STYLES 1 */

/***********************************/
.page-footer {
    padding: 1rem 0;
    background-color: var(--color-dark-secondary);
    color: #fff;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 500;
    font-size: 0.6rem;
}


.privacy {
    color: inherit;
     
}

.madeby {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
   

    img {
        margin-right: .5rem;
    }

}