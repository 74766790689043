/***********************************/
 
/* PAGES STYLES */

/***********************************/
.page-section {
    position: relative;
    padding: 2.5rem 0;
    scroll-margin-top: 1.5rem;

    &-top {
        margin-bottom: 3.3335rem;
        text-align: center;
    }

    &__title {
        font-size: 2.33333rem;
        font-weight: 800;
    }

    &:last-of-type {
        padding-bottom: 0;
    }
}

.intro-section {
    position: relative;
    padding-top: 3.5rem;
    background-image: url(../images/bg_main.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

@keyframes followpath {
    to {
        motion-offset  : 100%;
        offset-distance: 100%;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-15%);
    }

    100% {
        transform: translateY(0);
    }
}

.intro {

    &-image {
        position: absolute;
        top: 0; left: 0;
        transform: rotateZ(180deg);
        offset-path: path('M2193.24 -217.435C2193.24 -217.435 2022 -60 1880 -14C1578.91 83.5375 1216 182 1048 394C881.03 604.7 700 882 442 948C260.199 994.507 -136 968 -136 968');
        offset-rotate: reverse;
        // animation: followpath 15s linear infinite;
        animation: bounce 5s ease-in-out infinite, followpath 20s linear infinite;
        will-change: transform;
        z-index: 0;

        &:hover {
            animation-play-state: paused;
        }
    
    }

    &-title {
        font-size: 3rem;
        font-weight: 800;
    }
}

.intro-top {
    display: flex;
    margin-bottom: 4rem;

    &-body {
        flex: 0 0 50%;
        max-width: 50%;
        margin-top: 3rem;
        padding-right: 4rem;
    }

    &-image {
        max-width: 50%;
        min-height: 840px;
        img {
            position: absolute;
            right      : 0;
        }
    }

}

.intro-bottom {
    display: flex;

    &-body {
        flex         : 0 0 50%;
        margin-left: auto;
        max-width    : 50%;
        margin-top   : 3rem;
    }

    &-image {
        max-width : 50%;
        min-height: 840px;

        img {
            position: absolute;
            left   : 0;
        }
    }

    &-title {
        font-weight: 800;
        font-size: 1.666rem;
    }

    &-text {
        padding-right: 5.5rem;
    }

    &-benefits {
        display: flex;
    }

    &-benefit {
        max-width: 50%;
        font-size: .9rem;
        font-weight: 800;

        span {
            font-size: 2.66666rem;
            color:var(--color-primary);
        }

    }

}

.works-section {
    margin-top: -15rem;
}

.works {
    &__ill {
        position: absolute;
        right: 0;
        transform: translateX(50%);
        z-index: 2;
    }
}

.works-body {
    display: flex;
    align-items: center;
      min-height: 1300px;

    &-text {
        flex: 0 0 50%;
        max-width: 50%;

        &__title {
            font-size: 1.666666rem;
            font-weight: 800;
        }

        &__special {
            display: inline-block;
        }

    }

    &__bg {
        position: absolute;
        top     : 0;
        right   : 0;
        z-index: 1;
    }

    &__ill {
        position: absolute;
        top: 55%;
        right: 0;
        transform: translateY(-50%);
        z-index: 3;
    }

}

.works-additional {
    display: flex;
    align-items: flex-start;

    &-icon {
        margin-right: 0.666rem;
    }

    &__title {
        font-size: 1rem;
    }

    &__description {
       font-size: 0.66666rem;
    }
}

.about {
    display: flex;
    align-items: flex-start;
    margin: 0 -20px;
}

.about-list {
    margin: 0;
    padding: 0 20px;
    flex: 1;
    list-style-type: none;

    > * + * {
        margin-top: 3.5rem;
    }

}

.about-item {
    max-width: 98%;
    &__title {
        font-size: .9rem;
        margin-bottom: .333rem;
    }

    &__text {
        font-size: .6666rem;
    }
}

.about-image {
    flex: 2;
    padding: 0 20px;
    background-image: url(../images/bg_service.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.services-section {
    background-image: url(../images/bg_shape.svg);
    background-repeat: repeat-x;
    background-position: center;
    background-size: contain;
    padding: 6.66666rem 0;
    margin: 2rem 0;
}

.services {
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-auto-rows: minmax(360px, auto);
    gap: 20px;
    margin-bottom: 3.3335rem;
}

.service {
    background-color: #fff;
    padding: 1.6666rem;
    border-radius: 10px;

    &:nth-child(1), &:nth-child(2) {
        grid-row-start: 1;
        grid-row-end: 3;
    }


    &-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        > * + * {
            margin-top: 0.66666rem;
        }
    }

    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 160px;
        border-radius: 50%;
    }

    &__title {
        text-align: center;
        font-weight: 800;
    }

    &-list {
        margin: 0;
        padding: 0 0.6666rem;
        list-style-type: none;
        list-style-image: url("../images/icons/check.svg");

        > * + * {
            margin-top: 0.66666rem;
        }

        &__item {
            font-size: 0.66666rem;
        }

    }

    > * + * {
        margin-top: 2rem;
    }

}

.actions {
    position: relative;
    counter-reset: action;
    
    > * + * {
        margin-top: 2.5rem;
    }
}

.action {
    // position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    // opacity: 0;

    &:first-child {
        // opacity: 1;
    }

    > * + * {
    //    margin-left: 6.66666rem;
    }

    &:nth-child(even) {
        flex-direction: row-reverse;
    }

    &-image {
        flex-shrink: 0;
    }

    &-title {
        position: relative;
        padding-left: 3ch;
        flex: 0 0 60%;
        max-width: 60%;
        font-size: 1.8rem;

        &::before {
            position: absolute;
            left: 0; top: 0;
            counter-increment: action;
            content: counter(action);
            color: var(--color-primary);
            font-size: 4rem;
            line-height: 1;
        }
    }

}

.offer-section {
    padding: 12.75rem 0;
    background-image: url(../images/bg_shape__small.svg);
    background-repeat: repeat-x;
    background-position: center;
    background-size: contain;
}

.success-message {
    display: none;
    margin-bottom: 1rem;
    text-align: center;


    &.show {
        display: block;
    }

}

.offer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    scroll-margin-top: 1.5rem;

    &-form {
        flex: 0 0 41.666%;
        max-width: 41.666%;
        padding: 2.5rem 3rem;
        border-radius: 10px;
        background-color: #fff;

        &-top {
            margin-bottom: 1.75rem;
            text-align: center;

            &__title {
                font-size: 1rem;
            }

            &__description {
                font-size: 0.66666rem;
            }

        }

    }

    &-body {
        flex: 0 0 50%;
        max-width: 50%;

        &__title {
            font-size: 1.6666rem;
            font-weight: 800;
        }

        &__description {
        }

    }

}

.offer-options {
    margin-top: 2.666666rem;
}

.offer-option {
    display: flex;
    align-items: flex-start;

    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width           : 75px;
        height          : 75px;
        margin-right: 1.16666rem;
        flex-shrink: 0;
        border-radius   : 50%;
    }

    &__title {
        margin-bottom: 0.3333rem;
        font-size: 0.86666rem;
        font-weight: 800;
    }

    &__description {
        font-size: 0.666666rem;
    }

    &-body {
        
    }

}

.team-intro {
    display: flex;
    margin: 0 -20px 5rem;
}

.team-body {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 20px;

    &__title {
        font-size: 1.66666rem;
        font-weight: 800;
    }

    &__description {
        line-height: 1.3;
    }

    &__description:first-of-type {
        max-width: 20.3333rem;
        
    }

}

.team-benefits {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 20px;

    

}

.team-benefit {
    display: flex;
    align-items: center;


    &-icon {
        margin-right: 1.5rem;
    }

    &-description {
        font-size: 0.66666rem;
    }

    &-body {
        >*+* {
            margin-top: 0.333333rem;
        }
    }

}

.team {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px 5rem;
}

.team-member {
    flex: 0 0 25%;
    max-width: 25%;
    margin: 2rem 0;
    padding: 0 20px;

    &-image {
        position: relative;
        margin-bottom: 1.5rem;
        padding-bottom: 97%;
        background-image   : url(../images/star.png);
        background-position: center;
        background-size    : cover;
        background-repeat: no-repeat;

        img {
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            object-fit: cover;
            mask: url(../images/mask.svg);
            mask-repeat: no-repeat;
            mask-size: cover;
            z-index: -1;
        }

    }

    &-body {
        text-align: center;

        &__title {
            margin-bottom: 0.33333rem;
        }

        &__description {
            font-size: 0.666666rem;
        }
    }

}

.team-docs {
    display: flex;
    justify-content: space-between;
}

.team-doc {
    display: flex;
    align-items: center;
    max-width: 33%;
    flex: 0 0 33%;
    color: inherit;

    &-icon {
        margin-right: 1rem;
    }

    &__title {
        max-width: 85%;
        font-size: 0.66666666rem;
    }

}

.accordeon-item {
    padding: 2.333333rem 0;
    transition: background .2s ease-in-out;
    color: var(--color-primary);

    .accordeon-toggler {
        border-color: currentColor;
        transform   : rotateZ(180deg);
    }

    .accordeon-body {
        display: none;
    }

    &.active {
        color: var(--color-dark);
        background-color: var(--color-gray);

        .accordeon-toggler {
            border-color: currentColor;
            transform: rotateZ(0);
        }

    
 

    }
}

.fear {
    &-top {
        display        : flex;
        align-items    : center;
        justify-content: space-between; 
        cursor: pointer;
    }

    &__title {
        font-weight: 800;
        font-size: 1.2rem;
        color: var(--color-dark);
    }

    &-toggler {
        flex-shrink: 0;
        width: 3rem;
        height: 3rem;
        border: 3px solid currentColor;
        border-radius: 50%;
        background-color: transparent;
        pointer-events: none;
        
        transition: border-color .2s ease-in-out, transform .2s ease-in-out;

        color: inherit;

        path {
            stroke: currentColor;
        }

    }

    &-body {
        
    }

    &__description {
        max-width: 75%;
        padding: 1rem 0 .5rem;
        font-size: 0.6666rem;

        color: var(--color-dark);

    }

}

.brands {
    display: grid;
    grid-template-columns: repeat(4, 4fr);
    gap: 2rem 1.5rem;
    max-width: 85%;
    margin: 0 auto;
}

.brand {
    min-width: 210px;
    min-height: 80px;
    background-color: var(--color-gray);
}

.addresses {
    display: flex;
    justify-content: space-around;
}

.address {
    display: flex;
    flex-direction: column;
    align-items: center;

    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-bottom: 5rem;

    text-align: center;

    &-image {
        width: 160px;
        height: 160px;
        margin-bottom: 1rem;
        background-color: var(--color-gray);
        border-radius: 50%;
    }

    &-body {
        
        font-style: normal;
        > * {
            color: inherit;
            text-decoration: none;
            font-style: normal;
        }
    }

}

.map {
    position: relative;
    padding-bottom: 40%;
    background-color: var(--color-gray);
    mask       : url(../images/map_mask.svg);
    mask-repeat: no-repeat;
    mask-size  : cover;

    iframe {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        
    }
}