/***********************************/
 
/* HEADER STYLES */

/***********************************/
.page-header {
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    padding: .5rem 0;
    z-index: 50;

    transition: transform .2s ease-in-out;
    will-change: transform;

    &::before {
        content: '';
        position: absolute;
        top: 0; left: 0;
        display: block;
        width: 100%; height: 100%;
        background-color: #fff;
        box-shadow: 0px 4px 15px rgba(46, 54, 62, 0.05);

        opacity: 0;
        transition: opacity .2s ease-in-out;
        z-index: 1;
    }

    &.hide {
        transform: translateY(-100%);
    }

    &.sticky {
        &::before {
            opacity: 1;
        }
    }

}

.header {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 2;
    &__phone {
        text-decoration: none;
        color          : inherit;
    }

}

.header-menu {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
     font-size: 0.666666rem;

    > * + * {
        margin-left: 1rem;
    }

    &__link {
        text-decoration: none;
        color: inherit;
    }

}