/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
    padding                   : 15px 15px;
    display                   : inline-block;
    cursor                    : pointer;
    transition-property       : opacity, filter;
    transition-duration       : 0.15s;
    transition-timing-function: linear;
    font                      : inherit;
    color                     : inherit;
    text-transform            : none;
    background-color          : transparent;
    border                    : 0;
    margin                    : 0;
    overflow                  : visible;
}

.hamburger:hover {
    opacity: 1;
}

.hamburger.is-active:hover {
    opacity: 1;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: var(--color-primary);
}

.hamburger-box {
    width   : 25px;
    height  : 25px;
    display : inline-block;
    position: relative;
}

.hamburger-inner {
    display   : block;
    top       : 50%;
    margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width                     : 100%;
    height                    : 2px;
    background-color          : var(--color-primary);
    border-radius             : 0px;
    position                  : absolute;
    transition-property       : transform;
    transition-duration       : 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}

.hamburger-inner::before {
    top: -8px;
}

.hamburger-inner::after {
    bottom: -8px;
}

.hamburger--spin .hamburger-inner {
    transition-duration       : 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
    transform                 : rotate(225deg);
    transition-delay          : 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
    top       : 0;
    opacity   : 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
    bottom    : 0;
    transform : rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}