/***********************************/
 
/* GLOBAL STYLES */

/***********************************/

:root {
    --color-primary: #FFBB00;
    --color-accent: #1B2AA8;
    --color-danger: #BF211E;
    --color-success: #06A77D;
    --color-dark: #2E363E;
    --color-dark-secondary: #282027;
    --color-gray: #D5DBE0;
    --color-bg-page: #fff;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: var(--color-primary);
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

*, *::before, *::after {
    box-sizing: border-box;
}

*:focus {
    outline-color: var(--color-primary);
}

html {
    font-size: 30px;
    scrollbar-width: thin;
    scrollbar-color: var(--color-primary) #fff ;
    scroll-behavior: smooth;
}

body {
    font-family: 'Gilroy', Arial, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    background-color: var(--color-bg-page);
    color: var(--color-dark);
}



h1, h2, h3, h4, h5 {
    margin: 0;
}

p {
    margin: 0;
}

img {
    display: block;
    max-width: 100%;
}


#webpage {
    position: relative;
    display       : flex;
    flex-direction: column;
    min-height    : 100vh;
    overflow-x: hidden;
}

.page-main {
    flex: 1 0 auto;
}

.container {
    max-width: 100%;
    width: 1520px;
    margin: 0 auto;
    padding: 0 15px;
}

.center {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}


.with-stack {
    > * + * {
        margin-top: 2rem;
    }

    > p:first-of-type { margin-top: 30px; }

    &__medium {
        > * + * {
            margin-top: 1.5rem;
        }
    }

    &__small {
        > * + * {
            margin-top: 0.33333rem;
        }
    }
}

.with-flex-row {
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;

    > * + * {
        margin-left: 1rem;
    }

}

.with-border {
    padding: .25rem .4rem;
    border : 5px solid var(--color-primary);
    border-radius: 5px;
}

@keyframes blink {
    from {
        left: -5rem;
    }
    to {
        left: 20rem;
    }
}

.btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 3.55rem;
    border: 1px solid var(--color-accent);
    border-radius: 5px;
    background-color: transparent;
    
    font-weight: 700;
    font-size: 0.6666rem;
    text-decoration: none;
    color: var(--color-accent);
    transition: background-color .2s ease-in-out, border-color .2s ease-in-out;
    transition-delay: .2s;
    overflow: hidden;

    &:focus {
        outline-color: var(--color-primary);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0; left: 0;
        width: 15px;
        height: 100%;
        background-color: rgba(255, 255, 255, .25);
        transform: skewX(15deg) translateX(-150%);
    }

    &-primary {
        background-color: var(--color-accent);
        color: #fff;

        &:hover {
            background-color: darken(#FFBB00, $amount: 5);
            border-color: darken(#FFBB00, $amount: 5);
        }
    }

    &:hover {
        &::after {
            animation: blink .5s ease-in-out forwards;
        }
    }

    cursor: pointer;
}

.element-wrapper {
    position: relative;
}

.input-group {
    position: relative;

    &__label {
        position: absolute;
        top: 50%; left: .55rem;
        transform: translateY(-50%);
        font-weight: 500;
        letter-spacing: .1em;
        font-size: 0.666rem;
        pointer-events: none;

        transform-origin: top left;
        transition: transform .2s ease-in-out;

        &.active {
            transform: translateY(-2rem) scale(0.7);
        }
    }

    &__element:not([type="submit"]) {
        width: 100%;
        padding: 0.55rem;
        border: 2px solid transparent;
        border-radius: 5px;
        background-color: var(--color-gray);

        &.valid {
            border-color: var(--color-success);
        }

        &.error {
            border-color: var(--color-danger);
        }

    }

    > .error {
        font-size: 0.65rem;
    }

    [type="submit"] {
        width: 100%;
      
    }

}